<template>
  <v-container id="urban_renewal-tables" fluid tag="section">
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('meeting.title')"
      class="px-5 py-3"
    >
      <v-col class="text-right">
        <dialog-message
          :dialog_message="dialog_message"
          :message="message"
        ></dialog-message>
        <v-btn color="red" dark class="mb-2 mr-2" @click="RemoveMeeting()">
          <v-icon left>mdi-pencil-minus</v-icon>
          {{ $t("common.delete") }}
        </v-btn>
        <v-dialog
          v-model="dialog_form"
          max-width="700px"
          @click:outside="close"
        >
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">
              <v-icon left>mdi-pencil-plus</v-icon>
              {{ $t("meeting.createMeeting") }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ $t("meeting.createMeeting") }}</span>
            </v-card-title>
            <v-card-text class="py-0">
              <v-container>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row dense>
                    <v-col cols="12">
                      <v-select
                        :items="renewalItems"
                        :rules="fieldRules"
                        :label="$t('meeting.urban_renewal')"
                        v-model="item.urban_renewal"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select
                        :items="meetingTypeList"
                        :rules="fieldRules"
                        :label="$t('meeting.meeting_type')"
                        v-model="item.meeting_type"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :label="$t('meeting.meeting_time')"
                        class="purple-input"
                        :rules="fieldRules"
                        v-model="meeting_time_format"
                        @click="openDatetime"
                        readonly
                      />
                      <datetime
                        ref="datetime"
                        input-style="display:none"
                        v-model="item.meeting_time"
                        type="datetime"
                        :rules="fieldRules"
                        format="yyyy/MM/dd HH:mm"
                      ></datetime>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="item.title"
                        :rules="fieldRules"
                        :label="$t('meeting.name')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="item.meeting_address"
                        :label="$t('meeting.meeting_address')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="item.attendance_owner_count"
                        :rules="fieldRules"
                        :disabled="votable_disabled"
                        :label="$t('meeting.attendance_owner_count')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="item.votable_count"
                        :rules="fieldRules"
                        :disabled="votable_disabled"
                        :label="$t('meeting.votable_count')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="item.audiences_count"
                        readonly
                        :label="$t('meeting.audiences_count')"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        :label="$t('meeting.required_land_rate_numerator')"
                        class="purple-input"
                        type='number'
                        v-model="item.required_land_rate_numerator"
                      />
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        :label="$t('meeting.required_land_rate_denominator')"
                        class="purple-input"
                        type='number'
                        v-model="item.required_land_rate_denominator"
                        ><v-icon slot="prepend">
                          mdi-slash-forward
                        </v-icon></v-text-field
                      >
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        :label="$t('meeting.required_land_area')"
                        class="purple-input"
                        type='number'
                        v-model="item.required_land_area"
                        readonly
                      />
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        :label="$t('meeting.required_building_rate_numerator')"
                        class="purple-input"
                        type='number'
                        v-model="item.required_building_rate_numerator"
                      />
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        :label="
                          $t('meeting.required_building_rate_denominator')
                        "
                        class="purple-input"
                        type='number'
                        v-model="item.required_building_rate_denominator"
                        ><v-icon slot="prepend">
                          mdi-slash-forward
                        </v-icon></v-text-field
                      >
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        :label="$t('meeting.required_building_area')"
                        class="purple-input"
                        type='number'
                        v-model="item.required_building_area"
                        readonly
                      />
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        type='number'
                        v-model="item.required_owner_rate_numerator"
                        :label="$t('meeting.required_owner_rate_numerator')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        type='number'
                        v-model="item.required_owner_rate_denominator"
                        :label="$t('meeting.required_owner_rate_denominator')"
                      ><v-icon slot="prepend">
                          mdi-slash-forward
                        </v-icon></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="item.required_owner_count"
                        :label="$t('meeting.required_owner_count')"
                        readonly
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-simple-table fixed-header height="190px">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left text-h4">
                                {{ $t("meeting.audiences") }}
                              </th>
                              <th class="text-right">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  @click="newAudiences()"
                                >
                                  <v-icon left>mdi-pencil-plus</v-icon>
                                  {{ $t("meeting.newAudiences") }}
                                </v-btn>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <template
                              v-for="(audience, index) in audiences_list"
                            >
                              <tr>
                                <td>
                                  <v-text-field
                                    v-model="audience.name"
                                  ></v-text-field>
                                </td>
                                <td>
                                  <v-btn
                                    color="red"
                                    class="mx-2"
                                    small
                                    @click="removeAudiences(index)"
                                    >{{ $t("common.delete") }}</v-btn
                                  >
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>

                    <v-col cols="12">
                      <p class="mb-2 v-label theme--light">{{ $t("meeting.notice_info") }}：</p>
                    </v-col>
                    <v-card elevation="5">
                      <v-row class="px-2">
                        <v-col cols="12" md="6">
                          <v-row>
                            <v-col cols="5" class="pr-0 py-0">
                              <v-text-field
                                v-model="notice_word"
                                placeholder="○○○○"
                                @change="setNotice()"
                                :label="$t('meeting.notice_no')"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="2" class="px-0 py-0">
                              <v-text-field
                                label="字第"
                                single-line
                                readonly
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" class="px-0 py-0">
                              <v-text-field
                                v-model="notice_number"
                                placeholder="○○○○○○"
                                @change="setNotice()"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="1" class="pl-0 py-0">
                              <v-text-field
                                label="號"
                                single-line
                                readonly
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="item.host"
                            :label="$t('meeting.host')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="item.contact_name"
                            :label="$t('meeting.contact_name')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="item.contact_phone"
                            :label="$t('meeting.contact_phone')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="item.annex"
                            :label="$t('meeting.annex')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-simple-table fixed-header>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left text-h4" style="width:80%">
                                    {{ $t("meeting.description") }}
                                  </th>
                                  <th class="text-right">
                                    <v-btn
                                      color="primary"
                                      dark
                                      class="mb-2"
                                      @click="newDescription()"
                                    >
                                      <v-icon left>mdi-pencil-plus</v-icon>
                                      {{ $t("meeting.newDescription") }}
                                    </v-btn>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <template
                                  v-for="(description, index) in item.description"
                                >
                                  <tr>
                                    <td>
                                      <v-textarea
                                        v-model="description.text"
                                        :label="getIndexText(index+1)"
                                        :rules="fieldRules"
                                        no-resize
                                      ></v-textarea>
                                    </td>
                                    <td>
                                      <v-btn
                                        color="red"
                                        class="mx-2"
                                        small
                                        @click="removeDescription(index)"
                                        >{{ $t("common.delete") }}</v-btn
                                      >
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-card>
                    <!-- <v-col class="text-right">
                      <v-dialog
                        v-model="dialog_owner"
                        max-width="500px"
                        @click:outside="closeOwner"
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn color="primary" dark class="mb-2" v-on="on">
                            <v-icon left>mdi-pencil-plus</v-icon>
                            {{ $t("meeting.removeOwner") }}
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title>
                            <span class="headline">{{ $t("meeting.removeOwner") }}</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row dense>
                                <v-data-table
                                  v-model="selected"
                                  :headers="ownerHeaders"
                                  :items="owners"
                                  :items-per-page="5"
                                  :loading="ownerLoading"
                                  multi-sort
                                  show-select
                                ></v-data-table>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeOwner">
                              {{
                              $t("common.cancel")
                              }}
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="resetOwner">
                              {{
                              $t("common.save")
                              }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>

                    <v-col cols="12">
                      <v-textarea
                        :label="$t('meeting.invited_owner_id_list')"
                        class="purple-input"
                        :loading="ownerLoading"
                        v-model="item.invited_owner_name_list"
                        no-resize
                        readonly
                      />
                    </v-col> -->
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                {{ $t("common.cancel") }}
              </v-btn>
              <v-btn color="blue darken-1" text :disabled="loading" @click="save">
                {{ $t("common.save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalItems"
        :footer-props="footerProps"
        multi-sort
        show-select
        v-model="selected_meeting"
      >
        <template v-slot:item.meeting_time="{ item }">
          <div>{{ TransDate(item.meeting_time).split(" ")[0] }}</div>
          <div>{{ TransDate(item.meeting_time).split(" ")[1] }}</div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-flex>
            <v-btn
              color="primary"
              class="mx-2"
              small
              @click="showDetail(item)"
              >{{ $t("common.profile") }}</v-btn
            >
            <v-btn
              color="ocean"
              class="mx-2"
              small
              @click="showIssueList(item)"
              >{{ $t("common.votingIssue") }}</v-btn
            >
            <v-btn
              color="navy"
              class="mx-2"
              small
              @click="showSignIn(item)"
              >{{ $t("common.signIn") }}</v-btn
            >
            <v-btn
              color="orange"
              class="mx-2"
              small
              @click="showSignInRecord(item)"
              >{{ $t("common.showSignIn") }}</v-btn
            >
            <!-- <v-btn
              color="red"
              class="mx-2"
              small
              @click="RemoveMeeting(item)"
              >{{ $t("common.delete") }}</v-btn
            > -->
          </v-flex>
        </template>
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            dense
            :show-first-last-page="footerProps.showFirstLastPage"
            :show-current-page="footerProps.showCurrentPage"
            :first-icon="footerProps.firstIcon"
            :last-icon="footerProps.lastIcon"
            :prev-icon="footerProps.prevIcon"
            :next-icon="footerProps.nextIcon"
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            :itemsPerPageOptions="footerProps.itemsPerPageOptions"
          />
        </template>
        <template slot="no-data">
          <div>
            {{ $t('common.noData') }}
          </div>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import VComponent from '../../../components/base/VComponent.vue';
import CommonUtil from "@/utils/commonutil.js";
export default {
  data: (vm) => ({
    dialog_message: false,
    valid: true,
    items: [],
    totalItems: 0,
    audiences_list: [],
    loading: false,
    dialog_form: false,
    dialog_owner: false,
    options: {},
    totalItems: 0,
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [5, 10, 20],
    },
    message: {
      title: "",
      text: "",
    },
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("meeting.name"),
        value: "title",
      },
      {
        sortable: true,
        text: vm.$i18n.t("meeting.urban_renewal"),
        value: "urban_name",
      },
      {
        sortable: true,
        text: vm.$i18n.t("meeting.meeting_time"),
        value: "meeting_time",
      },
      {
        sortable: false,
        text: vm.$i18n.t("meeting.attendance_owner_count"),
        value: "attendance_owner_count",
      },
      {
        sortable: false,
        text: vm.$i18n.t("meeting.votable_count"),
        value: "votable_count",
      },
      {
        sortable: false,
        text: vm.$i18n.t("meeting.audiences_count"),
        value: "audiences_count",
      },
      {
        sortable: false,
        text: vm.$i18n.t("meeting.issue"),
        value: "issue",
      },
      {
        sortable: false,
        // text: vm.$i18n.t("common.actions"),
        text: '',
        value: "actions",
      },
    ],
    ownerHeaders: [
      {
        sortable: false,
        text: vm.$i18n.t("owner.owner_name"),
        value: "owner_name",
      },
      {
        sortable: false,
        text: vm.$i18n.t("building.building_area"),
        value: "building_info.building_area",
      },
      {
        sortable: false,
        text: vm.$i18n.t("land.land_area"),
        value: "land_info.land_area",
      },
    ],
    meetingTypeList: [
      {
        value: 1,
        text: vm.$i18n.t("meeting.member"),
      },
      {
        value: 2,
        text: vm.$i18n.t("meeting.director"),
      },
      {
        value: 3,
        text: vm.$i18n.t("meeting.publicHearing"),
      },
    ],
    meetingType: {
      member: 1,
      director: 2,
      publicHearing: 3,
    },
    item: {
      title: null,
      urban_renewal: null,
      meeting_type: null,
      status: null,
      meeting_time: null,
      attendance_owner_count: null,
      required_owner_count: null,
      required_owner_rate_numerator: null,
      required_owner_rate_denominator: null,
      required_building_area: null,
      required_building_rate_numerator: null,
      required_building_rate_denominator: null,
      required_land_area: null,
      required_land_rate_numerator: null,
      required_land_rate_denominator: null,
      invited_owner_name_list: null,
      invited_owner_id_list: null,
      votable_count: null,
      audiences_count: 0,
      audiences: null,
      exclude_list: null,
      meeting_address:null,
      notice_no:null,
      host:null,
      contact_name:null,
      contact_phone:null,
      description: [],
      annex: null
    },
    fieldRules: [(v) => !!v || vm.$i18n.t("common.missing")],
    renewalItems: [],
    owners: [],
    totalOwners: 0,
    ownerLoading: false,
    selected: [],
    selected_meeting: [],
    meeting_time_format: null,
    notice_word: null,
    notice_number: null,
    votable_disabled: true,
    current_votable_count: 0,
    current_attendance_owner_count: 0
  }),
  components: {
    datetime: Datetime,
  },
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    ...mapMutations(["setTitle"]),
    close() {
      this.dialog_form = false;
      this.loading = false;
      this.$refs.form.reset()
      this.item = {
        title: null,
        urban_renewal: null,
        meeting_type: null,
        status: null,
        meeting_time: null,
        attendance_owner_count: null,
        required_owner_count: null,
        required_owner_rate_numerator: null,
        required_owner_rate_denominator: null,
        required_building_area: null,
        required_building_rate_numerator: null,
        required_building_rate_denominator: null,
        required_land_area: null,
        required_land_rate_numerator: null,
        required_land_rate_denominator: null,
        invited_owner_name_list: null,
        invited_owner_id_list: null,
        votable_count: null,
        audiences_count: 0,
        audiences: null,
        exclude_list: null,
        meeting_address:null,
        notice_no:null,
        host:null,
        contact_name:null,
        contact_phone:null,
        description: [],
        annex: null
      };
      this.meeting_time_format = null;
      this.audiences_list = [];
    },
    getApi() {
      this.loading = true;
      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/meeting/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
        params: {
            page: page,
            page_size: itemsPerPage
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    save() {
      this.$refs.form.resetValidation()
      if (!this.$refs.form.validate()) {
        return;
      }
      let required_valid = this.checkRateInput()
      if (!required_valid) {
        this.message.title = this.$t("save-fail");
        this.message.text = "請檢查成會比例";
        this.dialog_message = !this.dialog_message;
        return;
      }
      let voteable_valid = this.checkVoteable()
      if (!voteable_valid) {
        this.message.title = this.$t("save-fail");
        this.message.text = "請檢查出席/納入計算人數";
        this.dialog_message = !this.dialog_message;
        return;
      }
      let urban_item = this.renewalItems.find(
        (element) => element.value == this.item.urban_renewal
      );
      // create hn account
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/meeting/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token,
        },
      };
      let data = {
        title: this.item.title,
        meeting_time: this.item.meeting_time,
        urban_renewal_id: this.item.urban_renewal,
        urban_name: urban_item["text"],
        meeting_type: this.item.meeting_type,
        attendance_owner_count: this.item.attendance_owner_count,
        required_owner_count: this.item.required_owner_count,
        required_owner_rate_numerator: this.item.required_owner_rate_numerator,
        required_owner_rate_denominator: this.item.required_owner_rate_denominator,
        required_building_area: this.item.required_building_area,
        required_building_rate_numerator: this.item.required_building_rate_numerator,
        required_building_rate_denominator: this.item.required_building_rate_denominator,
        required_land_area: this.item.required_land_area,
        required_land_rate_numerator: this.item.required_land_rate_numerator,
        required_land_rate_denominator: this.item.required_land_rate_denominator,
        invited_owner_id_list: this.item.invited_owner_id_list,
        votable_count: this.item.votable_count,
        audiences_count: this.item.audiences_count,
        audiences: this.item.audiences,
        exclude_list: this.item.exclude_list,
        meeting_address:this.item.meeting_address,
        notice_no:this.item.notice_no,
        host:this.item.host,
        contact_name:this.item.contact_name,
        contact_phone:this.item.contact_phone,
        description: JSON.stringify(this.item.description),
        annex: this.item.annex
      };
      this.axios
        .post(url, data, config)
        .then(function (response) {
          currentObj.loading = false;
          alert("送出成功");
          currentObj.close();
          currentObj.getApi();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("save-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      this.totalItems = data.count;
      this.items = data.results;
    },
    showDetail(item) {
      this.$router.push({
        name: "MeetingDetail",
        query: {
          item: item.id,
        },
      });
    },
    setRenewItems(items) {
      this.renewalItems = [];
      items.results.forEach((element) => {
        let newItem = {
          value: element.id,
          text: element.name,
          contact_address: element.contact_address,
          contact_name: element.contact_name
        };
        this.renewalItems.push(newItem);
      });
    },
    showIssueList(item) {
      this.$router.push({
        name: "IssueList",
        query: {
          item: item.id,
        },
      });
    },
    showSignIn(item) {
      this.$router.push({
        name: "SignIn",
        query: {
          meeting: item.id,
        },
      });
    },
    showSignInRecord(item) {
      let routeData = this.$router.resolve({name: 'SignInRecord', query: {
          meeting: item.id
        }});
      window.open(routeData.href, '_blank');
      // this.$router.push({
      //   name: "SignInRecord",
      //   query: {
      //     meeting: item.id,
      //   },
      // });
    },
    setOwners(data) {
      this.owners = Object.assign([], data.owners);
      this.totalOwners = this.owners.length;
      let ownerIds = [];
      let ownerNames = [];
      let excludeIds = [];
      this.owners.forEach((owner) => {
        if (owner.status == 1) { //status: 1 => Init 
          ownerIds.push(owner.id);
          ownerNames.push(owner.owner_name);
        } else {
          excludeIds.push(owner.id);
        }
      });
      this.current_votable_count = ownerIds.length;
      this.current_attendance_owner_count = this.totalOwners;
      this.item.votable_count = ownerIds.length;
      this.item.attendance_owner_count = this.totalOwners;
      this.item.invited_owner_name_list = ownerNames.toString();
      this.item.invited_owner_id_list = ownerIds.toString();
      this.item.exclude_list = excludeIds.toString();
    },
    setAddress(data){
      let urban_item = this.renewalItems.find(
        (element) => element.value == this.item.urban_renewal
      );
      this.item.meeting_address = urban_item.contact_address;
    },
    setHost(data){
      let urban_item = this.renewalItems.find(
        (element) => element.value == this.item.urban_renewal
      );
      this.item.host = urban_item.contact_name;
    },
    closeOwner() {
      this.dialog_owner = false;
      this.ownerLoading = false;
    },
    resetOwner() {
      let selected_ids = [];
      this.selected.forEach((element) => {
        selected_ids.push(element.id);
      });
      let ownerNames = [];
      let ownerIds = [];
      let excludeIds = [];
      this.owners.forEach((item) => {
        if (selected_ids.indexOf(item.id) == -1) {
          ownerNames.push(item.owner_name);
          ownerIds.push(item.id);
        } else {
          excludeIds.push(owner.id);
        }
      });
      this.item.votable_count = ownerIds.length;
      this.item.attendance_owner_count = this.totalOwners;
      this.item.invited_owner_name_list = ownerNames.toString();
      this.item.invited_owner_id_list = ownerIds.toString();
      this.item.exclude_list = excludeIds.toString();
      this.closeOwner();
    },
    openDatetime() {
      this.$refs.datetime.isOpen = true;
    },
    RemoveMeeting() {
      if (
        !confirm(
          this.$i18n.t("common.confirm") + this.$i18n.t("common.delete") + "？"
        )
      ) {
        return;
      }
      let selected_item = []
      this.selected_meeting.forEach(element=>{
        selected_item.push(element.id)
      })
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/meeting/remove/";
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
        params: {
          targets: selected_item.toString()
        }
      };
      this.axios
        .post(url, {}, config)
        .then(function (response) {
          alert("刪除成功。");
          currentObj.getApi();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("remove-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    getMeetingTypeCount() {
      let meeting_year = new Date(this.item.meeting_time).getFullYear();
      this.ownerLoading = true;
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/urban/" +
        this.item.urban_renewal +
        "/meeting_type/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
        params: {
          meeting_type: this.item.meeting_type,
          meeting_year: meeting_year,
        },
      };
      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.ownerLoading = false;
          currentObj.setMeetingTitle(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-data-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    setMeetingTitle(data) {
      let current_count = data["meeting_count"];
      switch (this.item.meeting_type) {
        case this.meetingType["member"]:
          current_count += 1;
          this.item.title =(new Date(this.item.meeting_time).getFullYear() - 1911) +
            "年度第" +
            current_count +
            "次會員大會";
          break;
        case this.meetingType["director"]:
          current_count += 1;
          this.item.title =(new Date(this.item.meeting_time).getFullYear() - 1911) +
            "年度第" +
            current_count +
            "次理監事會";
          break;
        case this.meetingType["publicHearing"]:
          this.item.title = "";
          break;
      }
    },
    checkRateInput(){
      let required_valid = true
      if(this.item.required_owner_rate_numerator != null && this.item.required_owner_rate_numerator.length == 0){
        this.item.required_owner_rate_numerator = null
      }
      if(this.item.required_owner_rate_denominator != null && this.item.required_owner_rate_denominator.length == 0){
        this.item.required_owner_rate_denominator = null
      }
      if(this.item.required_building_rate_numerator != null && this.item.required_building_rate_numerator.length == 0){
        this.item.required_building_rate_numerator = null
      }
      if(this.item.required_building_rate_denominator != null && this.item.required_building_rate_denominator.length == 0){
        this.item.required_building_rate_denominator = null
      }
      if(this.item.required_land_rate_numerator != null && this.item.required_land_rate_numerator.length == 0){
        this.item.required_land_rate_numerator = null
      }
      if(this.item.required_land_rate_denominator != null && this.item.required_land_rate_denominator.length == 0){
        this.item.required_land_rate_denominator = null
      }
      if(this.item.required_owner_rate_numerator != this.item.required_owner_rate_denominator){
        if(this.item.required_owner_rate_numerator==null||this.item.required_owner_rate_denominator==null){
          required_valid = false
          return required_valid
        }
      }
      if(this.item.required_building_rate_numerator != this.item.required_building_rate_denominator){
        if(this.item.required_building_rate_numerator==null||this.item.required_building_rate_denominator==null){
          required_valid = false
          return required_valid
        }
      }
      if(this.item.required_land_rate_numerator != this.item.required_land_rate_denominator){
        if(this.item.required_land_rate_numerator==null||this.item.required_land_rate_denominator==null){
          required_valid = false
          return required_valid
        }
      }
      return required_valid
    },
    checkVoteable(){
      let valid = true;
      if(this.item.votable_count > this.item.attendance_owner_count){
        valid = false;
      }
      return valid
    },
    newAudiences() {
      let new_item = {
        name: "",
      };
      this.audiences_list.push(new_item);
    },
    removeAudiences(item_index) {
      this.audiences_list.splice(item_index, 1);
    },
    setNotice(){
      this.item.notice_no = this.notice_word + "字第" + this.notice_number + "號";
    },
    newDescription() {
      let new_item = {
        text: "",
      };
      this.item.description.push(new_item);
    },
    removeDescription(item_index) {
      this.item.description.splice(item_index, 1);
    },
    getIndexText(index) {
      return CommonUtil.getIndexText(index)
    }
  },
  watch: {
    dialog_form(val) {
      if (val) {
        this.loading = true;
        const currentObj = this;
        const url = process.env.VUE_APP_SERVER_URL + "/backend/urban/";
        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + this.token,
          },
        };
        this.axios
          .get(url, config)
          .then(function (response) {
            currentObj.loading = false;
            currentObj.setRenewItems(response.data);
          })
          .catch(function (error) {
            console.log(error);
            console.log(error.response);
            currentObj.loading = false;
            currentObj.message.title = currentObj.$t("get-data-fail");
            currentObj.message.text = JSON.stringify(error.response.data);
            currentObj.dialog_message = !currentObj.dialog_message;
          });
      }
    },
    ["item.urban_renewal"](val) {
      if (val) {
        this.ownerLoading = true;
        const currentObj = this;
        const url =
          process.env.VUE_APP_SERVER_URL + "/backend/urban/" + val + "/owners/";
        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + this.token,
          },
        };
        this.axios
          .get(url, config)
          .then(function (response) {
            currentObj.ownerLoading = false;
            currentObj.setOwners(response.data);
            currentObj.setAddress(response.data);
            currentObj.setHost(response.data);
          })
          .catch(function (error) {
            console.log(error);
            console.log(error.response);
            currentObj.loading = false;
            currentObj.message.title = currentObj.$t("get-data-fail");
            currentObj.message.text = JSON.stringify(error.response.data);
            currentObj.dialog_message = !currentObj.dialog_message;
          });
      }
    },
    ["item.meeting_type"](val) {
      if (val) {
        if (this.item.meeting_time && this.item.urban_renewal) {
          this.getMeetingTypeCount();
        }
        if(val == this.meetingType.director){
          this.votable_disabled = false;
        } else {
          this.item.votable_count = this.current_votable_count;
          this.item.attendance_owner_count = this.current_attendance_owner_count;
          this.votable_disabled = true;
        }
      }
    },
    ["item.meeting_time"](val) {
      if (val) {
        if (this.item.meeting_type && this.item.urban_renewal) {
          this.getMeetingTypeCount();
        }
        this.meeting_time_format = this.TransDate(Date.parse(val));
      }
    },
    audiences_list:{
      handler: function(){
        let audiences_list = []
        this.audiences_list.forEach(element=>{
          if(element.name.trim().length!=0){
            audiences_list.push(element.name)
          }
        })
        this.item.audiences_count = audiences_list.length
        this.item.audiences = audiences_list.toString()
      },
      deep: true
    },
    options: {
      handler() {
        this.getApi();
      },
    },
  },
  mounted() {
    this.setTitle(this.$i18n.t("title.MeetingTables"));
    // this.getApi();
  }
};
</script>
